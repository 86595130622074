
// Class Definition
var LoginComponent = function() {
    var initValidation = function() {
        validatorLogin = formElLogin.validate({
            ignore: ":hidden",
            errorClass: "is-invalid",
            rules: {
                emailLogin: {
                    required: true,
                    email: true
                },
                passwordLogin: {
                    required: true
                },
            },
            // Submit valid form
            submitHandler: function (form) {
                apiClient.head('/sanctum/csrf-cookie')
                .then(response => {
                    apiClient.post(formElLogin.attr('action'), new FormData(formElLogin[0])).then(response => {
                      
                        if(response.data.status=="success"){
                            $('#modal-login').hide();
                            window.location.reload();

                        }
                    }).catch(function (error) {
                        HandlingError(error, validatorLogin);
                    });
                }).catch(function (error) {
                    HandlingError(error, validatorLogin);
                });
                
                return false;
            }

        });
    }

    return {
        init: function() {
            formElLogin = $('#LoginComponentForm');
            initValidation();
        }
    };
}();

// Class Initialization
$(function(){
    LoginComponent.init();
    
    
});